<template>
<div class="checkout-wrapper">

  <div id="scan_content">
    {{error}}
    <center>
      <table class='table'>
        <tr v-for="scan in scans" :key="scan.id">
          <td>
            {{scan.name}}
          </td>
          <td>
            {{scan.date}}
          </td>
          <td>
            <a :href="'/scan/'+scan.id" target='_blank'>Voir</a>
          </td>
        </tr>
      </table>
    </center>
  </div>

</div>
<Footer :language="language" :displayModalMenu="displayModalMenu" />
</template>

<script>

import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';
import {APIRequest} from '@simb2s/senseye-sdk'
import moment from 'moment'

export default {
  name: 'Scans',
  components: {
    Footer
  },
  data() {
    return {
      scans:[],
      error:''
    }
  },
  props:["displayModalMenu","language"],
  methods:{
    
  },
  async mounted()
  {
    try {
      let scans=await APIRequest.list('scans')
      this.scans=scans[0]
    } catch (error) {
      this.error="Une erreur s'est produite."
    }
    
  }
  
}
</script>
<style scoped>
.checkout-wrapper #scan_content
{
	display:inline-block;
	vertical-align:top;
	width:50%;
}
#scan_content
{
  margin-left:25%;

}
</style>